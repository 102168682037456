import { USER_TYPE } from "utils/constants";
import { QUESTION_TYPES } from "components/Quiz/utils";
import { RecordItem } from "types/common";
import { APP_LANGUAGES } from "utils/language";

export type PAGE_QUIZ_CTA_BUTTON_INFO = {
  ctaText: string;
  slug: string;
};

export type AddressAnswer = {
  latitude?: number;
  longitude?: number;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  zipCode?: string;
};

export type ContactAnswer = {
  firstName: string;
  lastName?: string;
  email?: string;
  phone?: string;
  company?: string;
  marketingSubscriber?: boolean;
  finalPhoneNumber?: string;
};

export enum ImageType {
  Portfolio = "portfolio",
  Inspiration = "inspiration"
}

export type ImagesAnswer = Record<ImageType, string[]>;

export enum QuizQuestionDisplayType {
  Blocks = "blocks",
  Grid = "grid"
}

export enum QuizQuestionInputType {
  LongText = "longText",
  Slider = "slider"
}

export type QuizQuestionInputProps = {
  min?: number;
  max?: number;
  step?: number;
  defaultValue?: number | string;
  label?: string;
  placeholder?: string;
  valuePrefix?: string;
  formatAsCurrency?: boolean;
};

export enum QuizQuestionConditionOperator {
  Equals = "equals",
  In = "in"
}

export enum QuizQuestionFormat {
  Text = "text",
  Options = "optinos"
}

export type QuizQuestionCondition = {
  operator: QuizQuestionConditionOperator;
  dependsOn: string;
  value: string | string[];
};

export type QuizQuestionOption = {
  id: string;
  image?: RecordItem;
  primaryText: string;
  secondaryText?: string;
  metaTags: Array<{
    type: string;
    name: string;
    id: string;
    value: string;
  }>;
};

export type QuizQuestionImage = {
  alt?: string;
  url: string;
};

export type QuizQuestionVisibleFields = {
  name?: boolean;
  email?: boolean;
  phone?: boolean;
  company?: boolean;
};
export type QuizQuestion = {
  id: string;
  type: keyof typeof QUESTION_TYPES;
  displayType?: QuizQuestionDisplayType;
  gridColumns?: number;
  mobileGridColumns?: number;
  inputType?: QuizQuestionInputType;
  inputProps?: QuizQuestionInputProps;
  isAutoNextSlide?: boolean;
  answer?: QuizQuestionAnswer;
  allowMultiAnswers?: boolean;
  questionFormat?: QuizQuestionFormat;
  title: string;
  subtitle?: string;
  images?: QuizQuestionImage[];
  skipText?: string;
  showSkip?: boolean;
  buttonLabel?: string; // used for next button in QuizFooter
  webhookURL?: string;
  isRequired?: boolean;
  redirectLink?: {
    slug?: string;
  };
  skipLink?: {
    slug?: string;
  };
  additionalAddressInfoFields: {
    enabled?: boolean;
    title?: string;
    subtitle?: string;
    fields?: QuizAddressAdditionalInfoField[];
  };
  userType?: USER_TYPE;
  options?: QuizQuestionOption[];
  conditionalDisplay?: boolean;
  conditionalLogic?: QuizQuestionCondition[];
  steps?: string[];
  visibleFields?: QuizQuestionVisibleFields;
  skipTextClicked?: boolean;
  enablePhoneVerification?: boolean;
  enableSliderLoader?: boolean;
  defaultSelectedOptions?: string[];
};

export type InputAnswer = string | number;

export type QuizQuestionAnswer =
  | {
      id: string;
      path?: string;

      title?: string;
      primaryText?: string;
      imagesAnswer?: string[];
    }
  | ImagesAnswer
  | AddressAnswer
  | ContactAnswer
  | InputAnswer
  | string[];

export type QuizNextClickProps = {
  questionId?: string;
  answer?: QuizQuestionAnswer;
  houzzUserDetails?: RecordItem;
};

export enum ProgressBarAppearance {
  PRIMARY = "primary",
  ACCENT = "accent"
}

export type QuizAddressAdditionalInfoField = {
  id: string;
  title?: string;
  subtitle?: string;
  value?: string;
};

export type QuizAddressAdditionalFieldsResponse = {
  data?: {
    fields?: QuizAddressAdditionalInfoField[];
    image?: string;
  } | null;
  error?: string | null;
};

export type ApiQuizQuestion = {
  id: string;
  question_id: string;
  title: string;
  subtitle?: string;
  type: keyof typeof QUESTION_TYPES;
  question_format?: QuizQuestionFormat;
  is_required?: boolean;
  is_auto_next_slide?: boolean;
  display_type?: QuizQuestionDisplayType;
  allow_multi_answers?: boolean;
  input_type?: QuizQuestionInputType;
  input_props?: QuizQuestionInputProps;
  conditional_display?: boolean;
  conditional_logic?: QuizQuestionCondition[];
  additional_address_info_fields: {
    enabled?: boolean;
    title?: string;
    subtitle?: string;
    fields?: QuizAddressAdditionalInfoField[];
  };
  show_skip?: boolean;
  skip_text?: string;
  visible_fields?: QuizQuestionVisibleFields;
  steps?: string[];
  webhook_url?: string;
  redirect_link?: {
    slug?: string;
  };
  options?: QuizQuestionOption[];
  images?: QuizQuestionImage[];
  button_label?: string;
  skip_link?: {
    slug?: string;
  };
  grid_columns?: number;
  mobile_grid_columns?: number;
  enable_phone_verification?: boolean;
  enable_slider_loader?: boolean;
  default_selected_options?: string[];
};

export type ApiQuiz = {
  id: string;
  slug: string;
  meta_title?: string;
  share_title?: string;
  og_image_title?: string;
  og_image_subtitle?: string;
  og_image_path?: string;
  meta_description?: string;
  share_description?: string;
  canonical_url_override?: boolean;
  no_index?: boolean;
  canonical_url?: string;
  is_deleted?: boolean;
  questions?: ApiQuizQuestion[];
  language?: APP_LANGUAGES;
};

export type Quiz = {
  id: string;
  slug: string;
  metaTitle?: string;
  shareTitle?: string;
  ogImageTitle?: string;
  ogImageSubtitle?: string;
  ogImagePath?: string;
  metaDescription?: string;
  shareDescription?: string;
  canonicalUrlOverride?: boolean;
  noIndex?: boolean;
  canonicalUrl?: string;
  isDeleted?: boolean;
  questions: QuizQuestion[];
  language?: APP_LANGUAGES;
};
