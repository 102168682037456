import { useMemo } from "react";
import { createBrowserClient } from "@supabase/ssr";
import { SupabaseClient } from "@supabase/supabase-js";
import { supabaseUrl, supabaseAnonKey } from "./supabase";

let client: SupabaseClient | undefined;

function getSupabaseBrowserClient(options?: any) {
  if (client) {
    return client;
  }

  client = createBrowserClient(supabaseUrl || "", supabaseAnonKey || "", options);

  return client;
}

function useSupabaseBrowser(options?: any) {
  return useMemo(() => getSupabaseBrowserClient(options), [options]);
}

export default useSupabaseBrowser;
