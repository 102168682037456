import isEmpty from "lodash/isEmpty";

import * as zod from "zod";
import {
  AddressAnswer,
  ApiQuiz,
  ApiQuizQuestion,
  ContactAnswer,
  PAGE_QUIZ_CTA_BUTTON_INFO,
  Quiz,
  QuizAddressAdditionalFieldsResponse,
  QuizQuestion,
  QuizQuestionAnswer,
  QuizQuestionConditionOperator,
  QuizQuestionFormat,
  QuizQuestionInputProps,
  QuizQuestionInputType,
  QuizQuestionVisibleFields
} from "components/Quiz/quizTypes";
import { RecordItem } from "types/common";
import { APP_LANGUAGES } from "utils/language";

export const QUESTION_TYPES = {
  standard: "standard",
  address: "address",
  account: "account",
  input: "input"
};

export const isNextDisabled = (question?: QuizQuestion, answer?: QuizQuestionAnswer) => {
  if (!question?.type) return true;
  if (
    question.type === QUESTION_TYPES.address &&
    question.additionalAddressInfoFields?.enabled &&
    !formatAddress(answer as AddressAnswer)
  ) {
    return true;
  }
  if (
    question.type === QUESTION_TYPES.input &&
    question.inputType === QuizQuestionInputType.Slider &&
    question.isRequired
  ) {
    return question.answer === undefined || question.answer === null;
  }

  if (question.isRequired) {
    return isEmpty(answer);
  }
  return false;
};

export enum QUIZ_EVENT {
  QUIZ_ANSWER_SUBMITTED = "quiz_answer_submitted",
  QUIZ_STARTED = "quiz_started",
  QUIZ_COMPLETED = "quiz_completed",
  QUIZ_STYLE_IMAGES_SUBMITTED = "quiz_style_images_submitted",
  QUIZ_PHONE_VERIFICATION_STARTED = "quiz_phone_verification_started",
  QUIZ_PHONE_VERIFICATION_COMPLETED = "quiz_phone_verification_completed"
}

export const formatAddress = (address: RecordItem) => {
  if (!address?.addressLine1) return "";
  let finalAddress = address?.addressLine1 ? address?.addressLine1 : "";

  if (address?.addressLine2) {
    finalAddress = finalAddress ? `${finalAddress}, ${address?.addressLine2}` : address?.addressLine2;
  }

  if (address?.city && finalAddress) {
    finalAddress += `, ${address?.city}`;
  } else if (address?.city) {
    finalAddress += address?.city;
  }

  if (finalAddress && (address?.state || address?.zipCode)) {
    finalAddress += `, ${address?.state || ""} ${address?.zipCode || ""}`;
  } else if (address?.state || address?.zipCode) {
    finalAddress += `${address?.state || ""} ${address?.zipCode || ""}`;
  }

  return finalAddress;
};

export const formatAddressWithZipCode = (address: RecordItem) => {
  if (!address?.addressLine1) return "";

  return `${address.addressLine1}, ${address.city}, ${address.state} ${address.zipCode}`;
};

export const addressValidationSchema = zod.object({
  addressLine1: zod.string().min(1, {
    message: "Address is required"
  }),
  addressLine2: zod.string(),
  city: zod.string().min(1, {
    message: "City is required"
  }),
  state: zod.string().min(1, {
    message: "State is required"
  }),
  zipCode: zod
    .string()
    .min(1, {
      message: "Zip code is required"
    })
    .regex(/(^\d{5}$)|(^\d{5}-\d{4}$)/, {
      message: "Invalid zip code"
    })
});

export const capitalizeName = (str = "") => {
  return str
    .split(" ")
    .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
    .join(" ");
};

export const getAdjustedPosition = (currentPosition: string) => {
  return +currentPosition;
};

export const getQuizBody = ({
  questions,
  isHouzzLead,
  project,
  quizLanguage = APP_LANGUAGES.ENGLISH
}: {
  questions: QuizQuestion[];
  isHouzzLead?: boolean;
  project?: RecordItem;
  quizLanguage?: APP_LANGUAGES;
}) => {
  let quizBody: RecordItem[] = [];
  let clientDetails: RecordItem = {};
  let deal: RecordItem = {};
  const houzzLeadFields = isHouzzLead ? { projectId: project?.id } : {};
  let webhookURL = "";
  let redirectLink = quizLanguage === APP_LANGUAGES.SPANISH ? "/es" : "/";
  let isQuizValid = true;
  questions.forEach((questionItem) => {
    const projectType =
      questionItem.questionFormat === QuizQuestionFormat.Text
        ? questionItem?.options
            ?.find((questionAnswer) => {
              return (questionItem.answer as string[])?.[0] === questionAnswer.primaryText;
            })
            ?.metaTags?.find((tag) => tag?.name === "projectType")
        : null;

    if (projectType) {
      deal.type = projectType.value;
    }

    if (questionItem.webhookURL) {
      webhookURL = questionItem.webhookURL;
    }

    if (questionItem.redirectLink?.slug) {
      redirectLink = `/${questionItem.redirectLink.slug}`;
    }

    if (questionItem.type === QUESTION_TYPES.standard && checkQuizQuestionValid(questions, questionItem)) {
      quizBody = [
        ...quizBody,
        {
          question: {
            id: questionItem.id,
            title: questionItem.title,
            subtitle: questionItem.subtitle,
            type: questionItem.type,
            options: questionItem.options
          },
          questionFormat: questionItem.questionFormat,
          singleAnswer:
            questionItem.questionFormat === QuizQuestionFormat.Text
              ? questionItem.options?.find((opt) => opt.primaryText === (questionItem.answer as string[])?.[0])
              : undefined,
          optionsAnswer:
            questionItem.questionFormat === QuizQuestionFormat.Options
              ? questionItem.options?.filter((opt) => (questionItem.answer as string[])?.includes(opt.primaryText))
              : undefined,
          ...houzzLeadFields
        }
      ];
      if (questionItem.isRequired && isEmpty(questionItem.answer)) {
        isQuizValid = false;
      }
    }

    if (questionItem.type === QUESTION_TYPES.input && checkQuizQuestionValid(questions, questionItem)) {
      quizBody = [
        ...quizBody,
        {
          question: {
            id: questionItem.id,
            title: questionItem.title,
            subtitle: questionItem.subtitle,
            type: questionItem.type,
            inputType: questionItem.inputType
          },
          questionFormat: questionItem.questionFormat,
          answer: questionItem.answer,
          skipTextClicked: questionItem.skipTextClicked,
          ...houzzLeadFields
        }
      ];
    }

    if (questionItem.type === QUESTION_TYPES.address) {
      const answer = questionItem.answer as AddressAnswer;
      if (!isEmpty(answer)) {
        deal = {
          ...deal,
          addressLine1: answer.addressLine1,
          addressLine2: answer.addressLine2,
          city: answer.city,
          state: answer.state,
          zipCode: answer.zipCode,
          latitude: `${answer.latitude || ""}`,
          longitude: `${answer.longitude || ""}`,
          additionalInfoFields: questionItem.additionalAddressInfoFields?.fields ?? undefined
        };
      }
      if (
        questionItem.isRequired &&
        (isEmpty(answer) || isEmpty(answer.addressLine1) || isEmpty(answer.state) || isEmpty(answer.zipCode))
      ) {
        isQuizValid = false;
      }
    }

    if (questionItem.type === QUESTION_TYPES.account) {
      const { finalPhoneNumber, ...rest } = (questionItem.answer || {}) as ContactAnswer;
      clientDetails = {
        ...rest,
        phone: finalPhoneNumber && finalPhoneNumber.startsWith("+1") ? finalPhoneNumber.replace("+1", "") : rest.phone
      };
      if (questionItem.isRequired && isEmpty(rest.email)) {
        isQuizValid = false;
      }
    }
  });
  return {
    quizBody,
    clientDetails,
    deal,
    webhookURL,
    redirectLink,
    isQuizValid
  };
};

export const PAGE_QUIZ_CTA_BUTTON_MAP: {
  [key: string]: PAGE_QUIZ_CTA_BUTTON_INFO;
} = {
  "/investors": {
    ctaText: "Explore Investment Deals",
    slug: "investor-profile"
  },
  "/realtors": {
    ctaText: "Explore Partnership",
    slug: "realtor-partnership"
  },
  "/wholesalers": {
    ctaText: "Explore Partnership",
    slug: "wholesaler-partnership"
  },
  "/offer-structures/subject-to": {
    ctaText: "Explore Subject-To",
    slug: "subject-to-deal"
  },
  "/offer-structures/owner-financing": {
    ctaText: "Explore Owner Financing",
    slug: "owner-financing-deal"
  },
  "/offer-structures/renovation-profit-sharing": {
    ctaText: "Explore Renovation Profit Sharing",
    slug: "renovation-profit-sharing-deal"
  }
};

export const PAGE_QUIZ_DEFAULT_CTA_BUTTON: PAGE_QUIZ_CTA_BUTTON_INFO = {
  ctaText: "Get a Cash Offer",
  slug: "fast-cash-offer"
};

export const PAGE_QUIZ_DEFAULT_CTA_BUTTON_SPANISH: PAGE_QUIZ_CTA_BUTTON_INFO = {
  ctaText: "Obtener una oferta en efectivo",
  slug: "oferta-rapida-en-efectivo"
};

export const getAddressAdditionalFieldsFromApi = async (
  apiUrl: string,
  address?: string,
  ip?: string
): Promise<QuizAddressAdditionalFieldsResponse> => {
  if (!address) {
    return { data: null, error: null };
  }
  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        full_address: address,
        ip_address: ip
      })
    });
    if (!response.ok) {
      throw `Server error: [${response.status}] [${response.statusText}] [${response.url}]`;
    }

    const data = await response.json();
    return { data, error: null };
  } catch (err) {
    return { data: null, error: "Failed to fetch address additionalFields from api" };
  }
};

export const getQuizInputValidationSchema = (type?: QuizQuestionInputType, props?: QuizQuestionInputProps) => {
  if (!type) {
    return zod.object({
      inputField: zod.string().min(1)
    });
  }

  switch (type) {
    case QuizQuestionInputType.LongText:
      return zod.object({
        inputField: zod.string().min(1, {
          message: "Field is required"
        })
      });
    case QuizQuestionInputType.Slider:
      return zod.object({
        inputField: zod
          .number()
          .int()
          .min(props?.min ?? 0, {
            message: "Field is required"
          })
          .max(props?.max ?? 100, {
            message: "Field is required"
          })
      });
    default:
      return zod.object({
        inputField: zod.string().min(1, {
          message: "Field is required"
        })
      });
  }
};

export const checkQuizQuestionValid = (questions: QuizQuestion[], questionToValidate: QuizQuestion) => {
  if (!questionToValidate.conditionalLogic || !questionToValidate.conditionalDisplay) return true;
  let isValid = true;
  for (const condition of questionToValidate.conditionalLogic) {
    const dependsOnQuestion = questions.find((ques) => ques.id === condition?.dependsOn);
    const dependsOnQuestionAnswer = dependsOnQuestion?.answer;
    if (condition?.operator === QuizQuestionConditionOperator.Equals) {
      if (
        Array.isArray(dependsOnQuestionAnswer)
          ? dependsOnQuestionAnswer?.includes(condition?.value as string)
          : dependsOnQuestionAnswer === condition?.value
      ) {
        isValid = true;
      } else {
        isValid = false;
        break;
      }
    }

    if (condition?.operator === QuizQuestionConditionOperator.In) {
      if (Array.isArray(dependsOnQuestionAnswer)) {
        isValid = dependsOnQuestionAnswer?.some((ans) => (condition?.value as string[])?.includes(ans));
        if (!isValid) break;
      } else {
        isValid = (condition?.value as string[])?.includes(dependsOnQuestionAnswer as string);
        if (!isValid) break;
      }
    }
  }
  return isValid;
};

export const QUIZ_ACCOUNT_DEFAULT_STEPS = [
  "Get a competitive offer",
  "Fast closing process",
  "Expert guidance every step"
];

export const QUIZ_ACCOUNT_DEFAULT_VISIBLE_FIELDS = {
  name: true,
  email: true,
  phone: true,
  company: false
};

export const getQuizAccountVisibleFieldsValidationSchema = (visibleFields: QuizQuestionVisibleFields) => {
  const schemaObject: RecordItem = {};
  if (visibleFields.name) {
    (schemaObject.firstName = zod
      .string({
        required_error: "First name is required"
      })
      .min(1, {
        message: "First name is required"
      })
      .transform(capitalizeName)),
      (schemaObject.lastName = zod
        .string({
          required_error: "Last name is required"
        })
        .min(1, {
          message: "Last name is required"
        })
        .transform(capitalizeName));
  }

  if (visibleFields.email) {
    schemaObject.email = zod
      .string({
        required_error: "Email is required"
      })
      .min(1, {
        message: "Email is required"
      })
      .email({
        message: "Invalid email address"
      });
  }

  if (visibleFields.company) {
    schemaObject.company = zod
      .string({
        required_error: "Company Name is required"
      })
      .min(1, {
        message: "Company Name is required"
      })
      .transform(capitalizeName);
  }

  if (visibleFields.phone) {
    schemaObject.phone = zod
      .string({
        required_error: "Phone number is required"
      })
      .min(1, {
        message: "Phone number is required"
      })
      .regex(/\+1[0-9]{10}$/, {
        message: "Invalid phone number"
      });
  }

  return zod.object(schemaObject);
};

export const getQuizMetadata = ({ quiz, baseUrl, siteName }: { quiz: Quiz; baseUrl: string; siteName: string }) => {
  const metaTitle = quiz?.metaTitle ? `${siteName} | ${quiz.metaTitle}` : siteName;

  const ogImageTitle = quiz?.ogImageTitle || "";
  const ogImageSubtitle = quiz?.ogImageSubtitle || "";
  const ogImageFilePath = quiz?.ogImagePath || "";
  return {
    title: metaTitle,
    description: quiz?.metaDescription,
    openGraph: {
      images: `${baseUrl}/api/og?title=${ogImageTitle}&subTitle=${ogImageSubtitle}${
        ogImageFilePath ? `&imagePath=${ogImageFilePath}` : ""
      }`,
      title: quiz?.shareTitle,
      description: quiz?.shareDescription
    },
    robots: {
      index: !quiz?.noIndex
    },
    alternates:
      quiz?.canonicalUrlOverride && quiz?.canonicalUrl
        ? {
            canonical: quiz?.canonicalUrl
          }
        : null
  };
};

export const quizSliderSteps = (firstName = "", language = APP_LANGUAGES.ENGLISH) => {
  return language === APP_LANGUAGES.SPANISH
    ? [
        {
          id: 1,
          subtitle: "¡Noticias Emocionantes!",
          title: `¡Tu oferta personalizada en efectivo está a solo unos momentos, ${firstName}!`,
          icon: "/dream_house.svg"
        },
        {
          id: 2,
          subtitle: "Perspectivas del Mercado",
          title: "Estamos analizando las tendencias actuales del mercado para maximizar el valor de tu hogar.",
          icon: "/magnify-home.svg"
        },
        {
          id: 3,
          subtitle: "Proceso Sin Complicaciones",
          title: "Sin reparaciones, sin visitas, sin estrés. Solo una oferta en efectivo directa.",
          icon: "/pricing.svg"
        },
        {
          id: 4,
          subtitle: "Estás en Buena Compañía",
          title: "Únete a miles de propietarios satisfechos que han vendido con facilidad.",
          icon: "/relax.svg"
        },
        {
          id: 5,
          subtitle: "Tu Viaje Comienza",
          title: `¡Prepárate para desbloquear el valor en efectivo de tu hogar, ${firstName}!`,
          icon: "/promise-satisfaction.svg"
        }
      ]
    : [
        {
          id: 1,
          subtitle: "Exciting News!",
          title: `Your personalized cash offer is just moments away, ${firstName}!`,
          icon: "/dream_house.svg"
        },
        {
          id: 2,
          subtitle: "Market Insights",
          title: "We're analyzing current market trends to maximize your home's value.",
          icon: "/magnify-home.svg"
        },
        {
          id: 3,
          subtitle: "Hassle-Free Process",
          title: "No repairs, no showings, no stress. Just a straightforward cash offer.",
          icon: "/pricing.svg"
        },
        {
          id: 4,
          subtitle: "You're in Good Company",
          title: "Join thousands of satisfied homeowners who've sold with ease.",
          icon: "/relax.svg"
        },
        {
          id: 5,
          subtitle: "Your Journey Begins",
          title: `Get ready to unlock the cash value of your home, ${firstName}!`,
          icon: "/promise-satisfaction.svg"
        }
      ];
};

export const mapApiQuizQuestionsToQuestions = (apiQuestions?: ApiQuizQuestion[]): QuizQuestion[] => {
  if (!apiQuestions) return [];
  return apiQuestions.map((apiQuestion) => {
    return {
      id: apiQuestion.question_id,
      title: apiQuestion.title,
      subtitle: apiQuestion.subtitle,
      type: apiQuestion.type,
      questionFormat: apiQuestion.question_format,
      isRequired: apiQuestion.is_required,
      isAutoNextSlide: apiQuestion.is_auto_next_slide,
      displayType: apiQuestion.display_type,
      allowMultiAnswers: apiQuestion.allow_multi_answers,
      inputType: apiQuestion.input_type,
      inputProps: apiQuestion.input_props,
      conditionalDisplay: apiQuestion.conditional_display,
      conditionalLogic: apiQuestion.conditional_logic,
      additionalAddressInfoFields: apiQuestion.additional_address_info_fields,
      showSkip: apiQuestion.show_skip,
      skipText: apiQuestion.skip_text,
      visibleFields: apiQuestion.visible_fields,
      steps: apiQuestion.steps,
      webhookURL: apiQuestion.webhook_url,
      redirectLink: apiQuestion.redirect_link,
      options: apiQuestion.options,
      images: apiQuestion.images,
      buttonLabel: apiQuestion.button_label,
      skipLink: apiQuestion.skip_link,
      gridColumns: apiQuestion.grid_columns,
      mobileGridColumns: apiQuestion.mobile_grid_columns,
      enablePhoneVerification: apiQuestion.enable_phone_verification,
      enableSliderLoader: apiQuestion.enable_slider_loader,
      defaultSelectedOptions: apiQuestion.default_selected_options
    };
  });
};

export const mapApiQuizToQuiz = (apiQuiz?: ApiQuiz): Quiz | undefined => {
  if (!apiQuiz) return;
  return {
    id: apiQuiz.id,
    slug: apiQuiz.slug,
    metaTitle: apiQuiz.meta_title,
    shareTitle: apiQuiz.share_title,
    ogImageTitle: apiQuiz.og_image_title,
    ogImageSubtitle: apiQuiz.og_image_subtitle,
    ogImagePath: apiQuiz.og_image_path,
    metaDescription: apiQuiz.meta_description,
    shareDescription: apiQuiz.share_description,
    canonicalUrlOverride: apiQuiz.canonical_url_override,
    noIndex: apiQuiz.no_index,
    canonicalUrl: apiQuiz.canonical_url,
    isDeleted: apiQuiz.is_deleted,
    questions: mapApiQuizQuestionsToQuestions(apiQuiz.questions),
    language: apiQuiz.language
  };
};
